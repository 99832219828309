.PatchNotes {
  text-align: center;
  background-color: #282c34;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.PatchNotes h2 {
  color: #61dafb;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.tags, .mods {
  display: flex;
  gap: 10px;
}

.tag {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.tag.selected,
.tag:hover {
  background-color: #61dafb;
  color: #333;
}

.mods select {
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #444;
  color: white;
}

.release-notes {
  width: 80%;
  max-width: 800px;
  background: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.release {
  margin-bottom: 20px;
}

.release h3 {
  color: #61dafb;
  border-bottom: 2px solid #61dafb;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.release ul {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
  color: white;
}

.release ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.release ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #61dafb;
}

.info-icon {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  background-color: #61dafb;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  position: relative;
}

.modal-content {
  position: relative;
  color: #333;
}


.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #333;
}