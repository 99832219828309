/* client/src/components/FAQ.css */

.FAQ {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .FAQ h2 {
    color: #61dafb;
    margin-bottom: 30px;
    font-size: 2.5em;
  }
  
  .faq-item {
    width: 80%;
    max-width: 800px;
    background: #333;
    color: white;
    margin: 10px 0;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-item:hover {
    background: #444;
  }
  
  .faq-question {
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .faq-answer {
    margin-top: 10px;
    display: none;
    font-size: 1em;
  }
  
  .faq-item.active .faq-answer {
    display: block;
  }
  
  .faq-item.active .faq-question {
    color: #61dafb;
  }

  a{
    color: #1e90ff;
  }