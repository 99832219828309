/* client/src/components/ModList.css */

.ModList {
    text-align: center;
    background-color: #282c34;
    color: white;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .ModList h2 {
    color: #61dafb;
    margin-bottom: 20px;
  }
  
  .ModList ul {
    list-style-type: none;
    padding: 0;
  }
  
  .ModList li {
    background-color: #333;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    width: 80%;
    max-width: 600px;
    text-align: left;
  }
  