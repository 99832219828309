/* client/src/components/Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar-logo {
  height: 50px;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar a:hover {
  background-color: #61dafb;
  color: #333;
}

.navbar a:active {
  background-color: #21a1f1;
  color: white;
}

.navbar a:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(97, 218, 251, 0.6);
}
