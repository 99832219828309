/* client/src/components/InstallInstructions.css */

.InstallInstructions {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .InstallInstructions h2 {
    color: #61dafb;
    margin-bottom: 30px;
    font-size: 2.5em;
  }
  
  .InstallInstructions p {
    margin: 20px 0;
    font-size: 1.2em;
  }
  
  .InstallInstructions ol {
    text-align: left;
    margin: 20px 0;
    padding: 0 20px;
    list-style: none;
    counter-reset: step;
  }
  
  .InstallInstructions ol li {
    margin-bottom: 20px;
    font-size: 1.2em;
    display: flex;
    align-items: flex-start;
  }
  
  .InstallInstructions ol li::before {
    content: counter(step);
    counter-increment: step;
    background: #61dafb;
    color: #282c34;
    border-radius: 50%;
    display: inline-block;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 30px;
    margin-right: 15px;
    font-weight: bold;
    font-size: 1em;
  }
  
  .video-container {
    margin: 30px 0;
  }
  
  .video-container iframe {
    max-width: 100%;
    border: none;
  }
  
  a.download-link {
    display: inline-block;
    background-color: #61dafb;
    color: #282c34;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
  }
  
  a.download-link:hover {
    background-color: #21a1f1;
  }
  