/* client/src/components/Contests.css */

.Contests {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Contests h2 {
  color: #61dafb;
  margin-bottom: 20px;
}

.contest {
  background: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  padding: 20px;
  width: 80%;
  max-width: 800px;
}

.contest h3 {
  color: #61dafb;
  margin-bottom: 10px;
}

.contest p {
  margin: 10px 0;
}

.contest h4 {
  color: #61dafb;
  margin-top: 20px;
}

.contest ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.contest ul li {
  margin-bottom: 10px;
}

.contest ul li a {
  color: #1e90ff; /* High contrast blue for links */
  text-decoration: underline;
}

.contest ul li a:hover {
  color: #ff4500; /* High contrast orange for hover state */
}

.contest ul li a:focus {
  outline: 2px solid #ff4500; /* High contrast orange outline for focus state */
}

.contest ul li a:active {
  color: #ff4500; /* High contrast orange for active state */
}

.contest ul li a:visited {
  color: #9370db; /* High contrast purple for visited state */
}
