.ConfigFiles {
  text-align: center;
  background-color: #282c34;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ConfigFiles h2 {
  color: #61dafb;
  margin-bottom: 20px;
}

.folder-select {
  margin-bottom: 20px;
}

.folder-select select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #61dafb;
  background-color: #333;
  color: white;
  font-size: 1rem;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.folder-select select:focus {
  outline: none;
  background-color: #444;
  border-color: #61dafb;
}

.folder-select option {
  background-color: #333;
  color: white;
}

ul {
  list-style: none;
  padding: 0;
  text-align: left;
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}

.file,
.folder {
  cursor: pointer;
  color: #61dafb;
  padding: 10px;
  border: 1px solid #61dafb;
  margin: 5px 0;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.file:hover,
.folder:hover {
  background-color: #61dafb;
  color: #282c34;
}

.file-content {
  margin-top: 20px;
  width: 100%;
  background: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-content h3 {
  color: #61dafb;
  border-bottom: 2px solid #61dafb;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.file-content pre {
  text-align: left;
  color: white;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
}

.font-size-controls {
  margin-bottom: 10px;
}

.font-size-controls button {
  background-color: #61dafb;
  color: #282c34;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.font-size-controls button:hover {
  background-color: #21a1f1;
}

.toggle-button {
  background-color: #61dafb;
  color: #282c34;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #21a1f1;
}

.search-input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #61dafb;
  background-color: #333;
  color: white;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.search-input:focus {
  outline: none;
  background-color: #444;
  border-color: #61dafb;
}

#responsive-iframe {
  width: 100%;
  height: 300px;
  border: none;
}