/* client/src/App.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: #1d2025;
  padding: 20px;
  color: white;
}

.App-main {
  flex: 1;
  background-color: #282c34;
  padding: 20px;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.server-status {
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: left;
  position: relative;
}

.server-status p {
  margin: 5px 0;
}

.status-online {
  color: #4caf50; /* Green for online status */
}

.status-offline {
  color: #f44336; /* Red for offline status */
}

.status-fetching {
  color: #ffa726; /* Orange for fetching status */
}

.refresh-button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.refresh-button:hover {
  transform: rotate(360deg);
  color: #21a1f1;
}

.trigger-funny-effect-button {
  background-color: #61dafb;
  color: #282c34;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.trigger-funny-effect-button:hover {
  background-color: #21a1f1;
  display:block;
}

.spinScreen {
  animation: spin infinite 3s linear;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.spinElements {
  animation: spin infinite 3s linear;
}

.resizeElements {
  animation: resize infinite 3s linear;
}

@keyframes resize {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.5); }
}
.home-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin-top: 20px;
}

.home-content section {
  background-color: #333;
  color: white;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 300px;
  max-width: 400px;
  text-align: left;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-content section:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.home-content section h2 {
  color: #61dafb;
  margin-bottom: 10px;
}

.home-content section p {
  margin-bottom: 10px;
}
