/* Custom midi-player style */
#midi-section midi-player {
    display: block;
    width: inherit;
    margin: 4px;
    margin-bottom: 0;
  }
  
  #midi-section midi-player::part(control-panel) {
    background: #333;
    border: 2px solid #444;
    border-radius: 10px 10px 0 0;
  }
  
  #midi-section midi-player::part(play-button) {
    color: #61dafb;
    border: 2px solid currentColor;
    background-color: #444;
    border-radius: 20px;
    transition: all 0.2s;
  }
  
  #midi-section midi-player::part(play-button):hover {
    color: #0a0;
    background-color: #61dafb;
    border-radius: 10px;
  }
  
  #midi-section midi-player::part(time) {
    color: #61dafb;
    font-family: monospace;
  }
  
  /* Custom midi-visualizer style */
  #midi-section midi-visualizer .piano-roll-visualizer {
    background: #282c34;
    border: 2px solid #444;
    border-top: none;
    border-radius: 0 0 10px 10px;
    margin: 4px;
    margin-top: 0;
    overflow: auto;
  }
  
  #midi-section midi-visualizer svg rect.note {
    fill: #61dafb;
    opacity: 0.6;
    stroke-width: 2;
    stroke: #444;
  }
  
  #midi-section midi-visualizer svg rect.note[data-instrument="0"] {
    fill: #e22;
    stroke: #500;
  }
  
  #midi-section midi-visualizer svg rect.note[data-instrument="2"] {
    fill: #2ee;
    stroke: #055;
  }
  
  #midi-section midi-visualizer svg rect.note[data-is-drum="true"] {
    fill: #888;
    stroke: #888;
  }
  
  #midi-section midi-visualizer svg rect.note.active {
    fill: #fff;
    opacity: 0.9;
    stroke: #fff;
  }
  
  svg rect.note {
    fill: rgb(calc(var(--midi-velocity) * 1.2 + 100), 30, calc(255 - var(--midi-velocity)));
  }
  
  svg rect.note.active {
    stroke: black;
    stroke-width: 1.5;
    stroke-opacity: 0.5;
  }