/* client/src/components/Rules.css */

.Rules {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Rules h2 {
    color: #61dafb;
    margin-bottom: 30px;
    font-size: 2.5em;
  }
  
  .Rules p.disclaimer {
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .rules-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
  }
  
  .rule-card {
    background: #333;
    color: white;
    margin: 10px 0;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .rule-number {
    background: #61dafb;
    color: #282c34;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-weight: bold;
    font-size: 1.5em;
    margin-right: 20px;
    flex-shrink: 0;
  }
  
  .rule-content {
    text-align: left;
    flex-grow: 1;
  }
  
  .rule-content h3 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
    color: #61dafb;
  }
  
  .rule-content ul {
    list-style-type: disc;
    margin: 10px 0 0 20px;
    padding: 0;
  }
  
  .rule-content ul li {
    margin-bottom: 5px;
  }
  
  .punishments,
  .issues {
    text-align: left;
    margin: 20px 0;
    padding: 0 20px;
    font-size: 1.2em;
    width: 100%;
    max-width: 800px;
  }
  
  .Rules h3 {
    color: #61dafb;
    margin-top: 30px;
  }
  
  .punishments ul,
  .issues ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  