/* Gallery.css */

/* Container for the gallery */
.gallery-container {
    padding: 20px;
    text-align: center;
    background-color: #282c34; /* Matches your app's background */
    color: white; /* Ensures text is visible on dark background */
  }
  
  .gallery-container h2 {
    margin-bottom: 20px;
    color: #61dafb; /* Consistent with your app's accent color */
  }
  
  /* Masonry layout */
  .gallery-grid {
    column-count: 3;
    column-gap: 15px;
  }
  @media (max-width: 1200px) {
      .gallery-grid {
      column-count: 2;
      }
  }

  @media (max-width: 768px) {
      .gallery-grid {
      column-count: 1;
      }
  }
  
  .gallery-item {
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px; /* Consistent with other sections */
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
    outline: 2px solid #61dafb; /* Visible focus outline */
  }
  
  /* Lightbox modal styles */
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(40, 44, 52, 0.9); /* Semi-transparent background */
    z-index: 1000;
    overflow: auto;
  }
  
  .lightbox-content {
    margin: 3% auto;
    display: block;
    max-width: 80%;
    max-height: 80%;
    border-radius: 8px;
  }
  
  .close {
    position: fixed;
    top: 20px;
    right: 35px;
    color: #61dafb; /* Accent color for close button */
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close:hover {
    color: #21a1f1; /* Slightly brighter on hover */
  }
  
  /* Caption under each gallery item */
  .gallery-item-caption {
    margin-top: 5px;
    color: #ccc; /* Light gray for captions */
    font-size: 0.9rem;
    text-align: center;
  }
  
  /* Lightbox caption */
  .lightbox-caption {
    margin-top: 10px;
    color: white;
    text-align: center;
  }
  
  .lightbox-caption h2 {
    margin-bottom: 5px;
    font-size: 1.5rem;
  }
  
  .lightbox-caption p {
    font-size: 1rem;
  }
  .owner-filter {
    margin-bottom: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .owner-filter label {
    margin-right: 10px;
    font-size: 1rem;
  }
  
  .owner-filter select {
    padding: 5px 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #61dafb;
    background-color: #333;
    color: white;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .owner-filter select:focus {
    outline: none;
    background-color: #444;
    border-color: #61dafb;
  }