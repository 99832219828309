/* client/src/components/Footer.css */

.footer {
  padding: 1rem;
  background-color: #333;
  color: white;
  text-align: center;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-links {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.footer-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.footer-link:hover {
  background-color: #555;
}

.footer-link svg {
  margin-right: 4px;
}
