.Recommended {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Recommended h2 {
    color: #61dafb;
    margin-bottom: 20px;
  }
  
  .Recommended ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
    width: 80%;
    max-width: 800px;
  }
  
  .Recommended ul li {
    margin-bottom: 10px;
  }
  
  .Recommended ul li a {
    color: #1e90ff; /* High contrast blue for links */
    text-decoration: underline;
  }
  
  .Recommended ul li a:hover {
    color: #ff4500; /* High contrast orange for hover state */
  }
  
  .Recommended ul li a:focus {
    outline: 2px solid #ff4500; /* High contrast orange outline for focus state */
  }
  
  .Recommended ul li a:active {
    color: #ff4500; /* High contrast orange for active state */
  }
  
  .Recommended ul li a:visited {
    color: #9370db; /* High contrast purple for visited state */
  }
  